import React from "react";
import {
  NumberInput,
  Create,
  required,
  List,
  Datagrid,
  TextField,
  DateField,
  SimpleForm,
} from "react-admin";

export const CommissionList = (props) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="rate" label="Comisión (%)" />
      <DateField
        source="createdAt"
        label="Válido desde:"
        showTime
        locales="es-ES"
      />
    </Datagrid>
  </List>
);

export const CommissionCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput
        source="rate"
        label="Comisión(%)"
        placeholder="%"
        inputProps={{
          min: 0,
          max: 100,
        }}
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
