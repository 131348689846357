import React, { useEffect } from "react";
import {
  DateField,
  DateInput,
  SelectInput,
  FormTab,
  TabbedForm,
  useRefresh,
  required,
  Filter,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  BooleanInput,
  TextInput,
  useRecordContext,
  SimpleShowLayout,
} from "react-admin";
import Grid from "@mui/material/Grid";
import Map from "./Map";

const DeliveryFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Estado"
      source="state"
      choices={[
        { id: "Pendiente de confirmación", name: "Pendiente de confirmación" },
        { id: "Confirmado", name: "Confirmado" },
        { id: "En proceso", name: "En proceso" },
        { id: "Pendiente de Pago", name: "Pendiente de Pago" },
        { id: "Concluido", name: "Concluido" },
        { id: "Reviewed", name: "Reviewed" },
        {
          id: "Cancelada por el proveedor",
          name: "Cancelada por el proveedor",
        },
        { id: "Cancelada por el cliente", name: "Cancelada por el cliente" },
      ]}
    />
    <DateInput label="Fecha de Inicio(desde)" source="date1" />
    <DateInput label="Fecha de Inicio(hasta)" source="date2" />
    <TextInput label="Empleado" source="employeeName" />
  </Filter>
);

export const DeliveryList = (props) => {
  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(() => refresh(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <List filters={<DeliveryFilter />} {...props}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <DateField
            source="startDate"
            label="Fecha de Inicio"
            showTime
            locales="es-ES"
          />
          <TextField source="settings.title" label="Tipo" />
          <TextField source="state" label="Estado" />
          <TextField source="price" label="Precio(Bs.)" />
          <TextField source="employee.fullName" label="Empleado Asignado" />
          <TextField source="commission" label="Comisión (Bs.)" />
          <BooleanField source="commissionPaid" label="Comisión Pagada" />
        </Datagrid>
      </List>
    </div>
  );
};

const DeliveryInfo = (props) => {
  const record = useRecordContext();
  return (
    <FormTab label="Información del Envío" {...props}>
      <SimpleShowLayout>
        <TextField source="settings.title" label="Tipo" />
        <TextField source="price" label="Precio(Bs)" />
        <TextField source="distance" label="Distancia(Km)" />
        <TextField source="state" label="Estado" />
        {record?.startDate ? (
          <DateField
            showTime
            source="startDate"
            label="Fecha de Inicio"
            locales="es-ES"
          />
        ) : (
          <DateField
            showTime
            source="createdAt"
            label="Fecha de Inicio"
            locales="es-ES"
          />
        )}
        <BooleanField source="immediate" label="Inmediata" />
        <BooleanInput
          validate={[required()]}
          source="commissionPaid"
          label="Comisión Pagada"
        />
      </SimpleShowLayout>
    </FormTab>
  );
};

const CustomerInfo = (props) => {
  const record = useRecordContext();
  return (
    <FormTab label="Información del Cliente" {...props}>
      <SimpleShowLayout>
        <TextField source="client.fullName" label="Nombre del Cliente" />
        <TextField source="client.phone" label="Teléfono" />
        {record.client.businessName && (
          <TextField source="client.businessName" label="Razón Social" />
        )}
        {record.client.NIT && <TextField source="client.NIT" label="NIT" />}
      </SimpleShowLayout>
      <div style={{ display: "inline-grid !important", width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <h3>Dirección de Origen</h3>
            <Map
              source={"origin.coordinates"}
              initialLatitude={record?.origin.coordinates.latitude}
              initialLongitude={record?.origin.coordinates.longitude}
              disabled
            />
            <span className="MuiTypography-root MuiTypography-body2">
              {record?.origin.street} #{record?.origin.house},{" "}
              {record?.origin.reference}
            </span>
            {record?.deliveryNote && (
              <div>
                <h3>Nota de Envío</h3>
                <span className="MuiTypography-root MuiTypography-body2">
                  {record?.deliveryNote}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Dirección(es) de Destino</h3>
            <Map
              source={"destination.coordinates"}
              initialLatitude={record?.destination[0].coordinates.latitude}
              initialLongitude={record?.destination[0].coordinates.longitude}
              disabled
            />
            {record?.destination.map((e, index) => {
              return (
                <span className="MuiTypography-root MuiTypography-body2">
                  {index + 1} - {e.street} #{e.house}, {e.reference}
                  <br />
                </span>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </FormTab>
  );
};

export const DeliveryEdit = () => {
  return (
    <Edit>
      <TabbedForm>
        <DeliveryInfo />
        <CustomerInfo />
        <FormTab label="Información del Empleado">
          <SimpleShowLayout>
            <ImageField source="employee.photo" label="Empleado" />
            <TextField source="employee.fullName" label="Nombre del Empleado" />
            <TextField source="employee.phone" label="Teléfono" />
          </SimpleShowLayout>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
