import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  SelectInput,
  FormDataConsumer,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

export const CouponCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nombre" validate={required()} />

        <TextInput
          source="description"
          label="Descripción"
          validate={required()}
          multiline
        />

        <DateInput
          source="expirationDate"
          label="Fecha de Expiración"
          validate={required()}
        />

        <NumberInput
          source="maxUses"
          label="Cantidad de usos disponibles"
          validate={required()}
        />

        <ReferenceArrayInput source="validServices" reference="service">
          <SelectArrayInput label="Servicios Válidos" />
        </ReferenceArrayInput>

        <SelectInput
          source="discountType"
          label="Tipo de descuento"
          choices={[
            { id: "Porcentaje", name: "Porcentaje" },
            { id: "Monto", name: "Monto" },
          ]}
          validate={required()}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.discountType === "Porcentaje") {
              return (
                <NumberInput
                  source="discount"
                  label="Descuento (%)"
                  validate={required()}
                  min={0}
                  max={100}
                  {...rest}
                />
              );
            }

            if (formData.discountType === "Monto") {
              return (
                <NumberInput
                  source="discount"
                  label="Descuento (Bs)"
                  validate={required()}
                  {...rest}
                />
              );
            }

            return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
