import React, { useState, useEffect } from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  DateInput,
  useRefresh,
  ArrayField,
  ChipField,
  SingleFieldList,
  useListContext,
} from "react-admin";
import Paper from "@mui/material/Paper";
import RegexSearchInput from "../../components/RegexSearchInput";

const postRowStyle = (record) => ({
  backgroundColor: !record?.paymentInfo.isFeePaid ? "#ffdfd4" : "",
});

const MyDatagrid = (props) => {
  let sum = 0;
  let fee = 0;
  let totalFee = 0;
  const { data } = useListContext();
  data?.map((e) => {
    sum += e.paymentInfo.totalPrice;
    totalFee += e.paymentInfo.feeAmount;
    if (e.paymentInfo.isFeePaid) {
      fee += e.paymentInfo.feeAmount;
    }
  });

  props.totalIn(sum, totalFee);
  props.commissionPaid(fee);
  return (
    <Datagrid bulkActionButtons={false} rowStyle={postRowStyle} {...props} />
  );
};

const BalanceFilter = (props) => (
  <Filter {...props}>
    <DateInput label="Desde" source="bookingDate>" />
    <DateInput label="Hasta" source="bookingDate<" />
    <RegexSearchInput label="Proveedor" source="provider.name" />
  </Filter>
);

export const BookingBalance = (props) => {
  let [totalIn, setTotalIn] = useState(0);
  let [totalCom, setTotalCom] = useState(0);
  let [commissionPaid, setCommissionPaid] = useState(0);

  const callbackFunction = (childData, totCom) => {
    setTotalIn(childData);
    setTotalCom((Math.round(totCom * 100) / 100).toFixed(2));
  };

  const callbackCommission = (childData) => {
    setCommissionPaid(childData);
  };

  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(() => refresh(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <List
        sort={{ field: "startDate", order: "ASC" }}
        bulkActionButtons={false}
        filters={<BalanceFilter />}
        {...props}
      >
        <MyDatagrid
          rowClick="edit"
          totalIn={callbackFunction}
          commissionPaid={callbackCommission}
        >
          <TextField source="_id" label="ID" />
          <TextField source="provider.name" label="Proveedor" />
          <ArrayField source="serviceCart" label="Servicios Solicitados">
            <SingleFieldList>
              <ChipField source="service.name" />
            </SingleFieldList>
          </ArrayField>
          <DateField
            source="bookingDate"
            label="Fecha de Inicio"
            showTime
            locales="es-ES"
          />
          <TextField
            source="paymentInfo.totalPrice"
            label="Precio Total (Bs.)"
          />
          <TextField
            source="paymentInfo.paymentMethod"
            label="Método de Pago"
          />
          <TextField source="paymentInfo.status" label="Estado de Pago" />
          <TextField source="paymentInfo.feeRate" label="% de Comisión" />
          <TextField source="paymentInfo.feeAmount" label="Comisión (Bs.)" />
          <BooleanField
            source="paymentInfo.isFeePaid"
            label="Comisión pagada"
          />
          <TextField source="feedbackInfo.rating" label="Rating" />
        </MyDatagrid>
      </List>
      <div>
        <Paper>
          <h3>Ingresos de Proveedores Totales: Bs.{totalIn}</h3>
          <h3>Comisiones Totales: Bs.{totalCom}</h3>
        </Paper>
        <Paper>
          <h3>Comisiones Totales: Bs.{totalCom}</h3>
          <h3>Comisiones Pagadas: Bs.{commissionPaid.toFixed(2)}</h3>
          <h3>
            Comisiones en deuda: Bs.{(totalCom - commissionPaid).toFixed(2)}
          </h3>
        </Paper>
      </div>
    </div>
  );
};
