import React from "react";
import {
  SelectInput,
  Filter,
  List as BaseList,
  Datagrid,
  TextField,
  DateInput,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
} from "react-admin";

const BookingFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Estado"
        source="status"
        choices={[
          { id: "Pendiente", name: "Pendiente" },
          { id: "Confirmado", name: "Confirmado" },
          { id: "En proceso", name: "En proceso" },
          { id: "Finalizado", name: "Finalizado" },
          {
            id: "Cancelada por el proveedor",
            name: "Cancelada por el proveedor",
          },
          { id: "Cancelada por el cliente", name: "Cancelada por el cliente" },
        ]}
      />
      <DateInput label="Desde" source="bookingDate>" />
      <DateInput label="Hasta" source="bookingDate<" />
    </Filter>
  );
};

export const List = (props) => (
  <BaseList
    sort={{ field: "bookingDate", order: "ASC" }}
    filters={<BookingFilter />}
    {...props}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="_id" label="ID" />
      <TextField source="provider.name" label="Proveedor" />

      <ArrayField source="serviceCart" label="Servicios Solicitados">
        <SingleFieldList>
          <ChipField source="service.name" />
        </SingleFieldList>
      </ArrayField>
      <DateField
        source="bookingDate"
        label="Fecha de Inicio"
        showTime
        locales="es-ES"
      />
      <TextField source="paymentInfo.totalPrice" label="Precio Total (Bs.)" />
      <TextField source="paymentInfo.paymentMethod" label="Método de Pago" />
      <TextField source="status" label="Estado de Reserva" />
      <TextField source="paymentInfo.status" label="Estado de Pago" />
      <TextField source="feedbackInfo.rating" label="Rating" />
      <BooleanField source="isInBranch" label="¿Servicio en Sucursal?" />
      <TextField source="branch.name" label="Sucursal" />
    </Datagrid>
  </BaseList>
);
