import React from "react";
import { styled } from "@mui/material/styles";
import {
  Edit as BaseEdit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import Map from "../../components/Map";

const PREFIX = "Edit";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseEdit = styled(BaseEdit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

// CustomToolbar con los botones de Guardar y Eliminar
const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("/services");
    refresh();
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este elemento? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};

export const Edit = ({ ...props }) => (
  <StyledBaseEdit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput validate={[required()]} source="name" label="Nombre" className={classes.inlineBlock} />
      <Map source="addressInfo" className={classes.inlineBlock} />
      <TextInput
        validate={[required()]}
        source="addressInfo.street"
        label="Calle"
        className={classes.inlineBlock}
      />
      <TextInput source="addressInfo.phone" label="Teléfono/Celular" className={classes.inlineBlock} />
      <ReferenceArrayInput
        source="services"
        reference="service"
        filter={{
          method: "Ambos,En sucursal",
          enabled: true
        }}
      >
        <SelectArrayInput label="Servicios" />
      </ReferenceArrayInput>

      <BooleanInput source="status" label="Estado" className={classes.inlineBlock} />
    </SimpleForm>
  </StyledBaseEdit>
);
