import React, { useState, useEffect } from "react";
import { List, Datagrid, TextField, BooleanField, DateField, DateInput, useRefresh, ArrayField, ChipField, SingleFieldList, useListContext, CreateButton, FilterButton, TopToolbar, SimpleForm, TextInput, NumberInput, useNotify, useDataProvider, SaveButton, DeleteButton, Button, Toolbar, useRedirect, Title, SelectInput, ReferenceInput, required, useChoicesContext, useResourceContext, Pagination } from "react-admin";
import Paper from "@mui/material/Paper";
import { Box, Container, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSearchParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

const postRowStyle = (record, index) => ({
  backgroundColor: !record?.paymentInfo.isFeePaid ? "#ffdfd4" : "",
});

const BalanceFilter = [<DateInput label="Desde" source="bookingDate>" />, <DateInput label="Hasta" source="bookingDate<" />];

const TransactionDialog = ({ open, handleClose, record, onDeleteSuccess, isEdit, onSuccess }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const provider = JSON.parse(localStorage.getItem("profile"));
  const providerId = provider ? provider._id : null;

  const handleCreateSave = async (values) => {
    try {
      const { employeeData, ...restValues } = values;
      const dataToSend = {
        ...restValues,
        provider: providerId,
        employee: {
          _id: employeeData._id,
          fullName: `${employeeData.first_name} ${employeeData.last_name}`,
          phone: employeeData.phone,
          photoURL: employeeData.picture,
          pushToken: employeeData.pushToken,
          CI: employeeData.CI_NIT,
          location: employeeData.location || [],
        },
      };
      await dataProvider.create("transaction", { data: dataToSend });
      notify("Transacción creada con éxito", { type: "success" });
      handleClose();
      onSuccess();
    } catch (error) {
      notify("Error al crear la transacción", { type: "error" });
    }
  };

  const handleEditSave = async (values) => {
    try {
      const dataToSend = {
        ...values,
        provider: providerId,
        employee: values.employeeData,
      };
      await dataProvider.update("transaction", { id: record._id, data: dataToSend });
      notify("Transacción actualizada con éxito", { type: "success" });
      handleClose();
      onSuccess();
    } catch (error) {
      notify("Error al actualizar la transacción", { type: "error" });
    }
  };

  const handleDelete = async () => {
    if (record) {
      try {
        await dataProvider.delete("transaction", { id: record._id });
        notify("Transacción eliminada con éxito", { type: "success" });
        redirect("/bookingBalance");
        onDeleteSuccess();
        handleClose();
      } catch (error) {
        notify("Error al eliminar la transacción", { type: "error" });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{record?.type ? `Detalles de ${record.type}` : "Crear transacción"}</DialogTitle>
      <DialogContent>
        <SimpleForm
          record={record}
          onSubmit={isEdit ? handleEditSave : handleCreateSave}
          toolbar={
            <Toolbar>
              <SaveButton resource="transaction" />
              {isEdit && <DeleteButton resource="transaction" record={record} label="Eliminar" onClick={handleDelete} />}
              <Button onClick={handleClose} style={{ marginLeft: 8 }}>
                Cerrar
              </Button>
            </Toolbar>
          }
          title={<Title title="" />}
        >
          <SelectInput
            source="type"
            label="Tipo de transacción"
            choices={[
              { id: "Egreso", name: "Egreso" },
              { id: "Ingreso", name: "Ingreso" },
            ]}
            disabled={isEdit}
          />
          <ReferenceInput
            label="Sucursal"
            source="branch"
            reference="enterprise/branches"
            filter={{
              status: true,
            }}
          >
            <SelectBranchInput source="branch" />
          </ReferenceInput>
          <ReferenceInput
            label="Empleado"
            source="employee"
            reference={"employee/provider/" + providerId}
            filter={{
              idProv: providerId,
              state: true,
            }}
          >
            <SelectEmployeeInput source="employee" />
          </ReferenceInput>
          <TextInput source="description" label="Descripción" />
          <NumberInput source="amount" label="Monto (Bs.)" />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

const ListActions = ({ onCreate, props }) => {  

  return (
    <TopToolbar>
      <Button onClick={onCreate} label="Crear Transacción" startIcon={<AddIcon />}></Button>      
    </TopToolbar>
  );
};

export const BalanceList = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [ingresosTotal, setIngresosTotal] = useState(0);
  const [otrosIngresosTotal, setOtrosIngresosTotal] = useState(0);
  const [egresosTotal, setEgresosTotal] = useState(0);
  const [otrosEgresosTotal, setOtrosEgresosTotal] = useState(0);


  const ingresosTotales = ingresosTotal + otrosIngresosTotal;
  const egresosTotales = egresosTotal + otrosEgresosTotal;
  const total = ingresosTotales - egresosTotales;

  const refresh = useRefresh();
  useEffect(() => {
    const interval = setInterval(() => refresh(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [refresh]);

  const handleRowClick = (record) => {
    setSelectedRecord(record);
    setIsEdit(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRecord(null);
  };

  const handleCreateClick = () => {
    setSelectedRecord(null);
    setIsEdit(false);
    setOpen(true);
  };

  const handleDeleteSuccess = () => {
    setOpen(false);
    setSelectedRecord(null);
  };

  return (
    <Container style={{ margin: 16, padding: 16, marginTop: 0, paddingTop: 0 }}>
      <ListActions onCreate={handleCreateClick} />
      <List 
        sort={{ field: "bookingDate", order: "ASC" }}
        resource="booking/balance"
        title=" "
        exporter={false}
        actions={false}
        perPage={10}
        disableSyncWithLocation       
      >
        <Box sx={{ margin: 0, padding: 0, borderBottom: "1px solid #ddd" }}>
          <Typography variant="h5" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
            Ingresos por Servicios
          </Typography>
        </Box>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="_id" label="ID" />
          <ArrayField source="serviceCart" label="Servicios Solicitados">
            <SingleFieldList>
              <ChipField sx={{ display: "flex", flexWrap: "wrap", maxWidth: 200 }} source="service.name" />
            </SingleFieldList>
          </ArrayField>
          <DateField source="bookingDate" label="Fecha de Inicio" locales="es-ES" options={{ day: '2-digit', month: '2-digit', year: 'numeric' }} />
          <TextField source="paymentInfo.totalPrice" label="Precio Total (Bs.)" />
          <TextField source="employee.fullName" label="Empleado" />
          <TextField source="paymentInfo.paymentMethod" label="Método de Pago" />
          <TextField source="paymentInfo.status" label="Estado de Pago" />      
          <TextField source="branch.name" label="Sucursal" />
        </Datagrid>
        <TotalAmount resource="booking/balance" setTotal={setIngresosTotal} showInline totalType="ingresos" />
      </List>

      <List 
        sort={{ field: "date", order: "ASC" }} 
        resource="transaction/provider/income" 
        title=" " 
        exporter={false} 
        actions={false}
        perPage={10}
        disableSyncWithLocation
      >
        <Box sx={{ margin: 0, padding: 0, borderBottom: "1px solid #ddd" }}>
          <Typography variant="h5" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
            Otros Ingresos
          </Typography>
        </Box>
        <Datagrid rowClick={(id, basePath, record) => handleRowClick(record)} bulkActionButtons={false}>
          <TextField source="description" label="Descripción" />
          <DateField source="date" label="Fecha" />
          <TextField source="amount" label="Monto (Bs.)" />
          <TextField source="branch.name" label="Sucursal" />
          <TextField source="employee.fullName" label="Empleado" />
        </Datagrid>
        <TotalAmount resource="transaction/provider/income" setTotal={setOtrosIngresosTotal} showInline />
      </List>
      <List
        sort={{ field: "bookingDate", order: "ASC" }}
        resource="booking/balance"
        title=" "
        exporter={false}
        actions={false}
        perPage={10}
        disableSyncWithLocation        
      >
        <Box sx={{ margin: 0, padding: 0, borderBottom: "1px solid #ddd" }}>
          <Typography variant="h5" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
            Egresos por Comisiones de Servicios
          </Typography>
        </Box>
        <Datagrid rowClick={(id, basePath, record) => handleRowClick(record)} bulkActionButtons={false} rowStyle={postRowStyle}>
          <TextField source="_id" label="ID" />
          <ArrayField source="serviceCart" label="Servicios Solicitados">
            <SingleFieldList>
              <ChipField sx={{ display: "flex", flexWrap: "wrap", maxWidth: 200 }} source="service.name" />
            </SingleFieldList>
          </ArrayField>
          <DateField source="bookingDate" label="Fecha de Inicio" locales="es-ES" options={{ day: '2-digit', month: '2-digit', year: 'numeric' }} />
          <TextField source="paymentInfo.totalPrice" label="Precio Total (Bs.)" />
          <TextField source="employee.fullName" label="Empleado" />
          <TextField source="paymentInfo.feeAmountEmployee" label="Comision para Empleado (Bs.)" />
          <TextField source="paymentInfo.feeRate" label="Tasa de Comisiones (%)" />
          <TextField source="paymentInfo.feeAmount" label="Comisiones (Bs.)" />
          <BooleanField source="paymentInfo.isFeePaid" label="Comisión pagada" />
        </Datagrid>
        <TotalAmount resource="booking/balance" setTotal={setEgresosTotal} showInline totalType="egresos" />
      </List>
      <List 
        sort={{ field: "date", order: "ASC" }} 
        resource="transaction/provider/expense" 
        title=" " 
        exporter={false} 
        actions={false}
        perPage={10}
        disableSyncWithLocation
      >
        <Box sx={{ margin: 0, padding: 0, borderBottom: "1px solid #ddd" }}>
          <Typography variant="h5" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
            Otros Egresos
          </Typography>
        </Box>
        <Datagrid rowClick={(id, basePath, record) => handleRowClick(record)} bulkActionButtons={false}>
          <TextField source="description" label="Descripción" />
          <DateField source="date" label="Fecha" />
          <TextField source="amount" label="Monto (Bs.)" />
          <TextField source="branch.name" label="Sucursal" />
          <TextField source="employee.fullName" label="Empleado" />
        </Datagrid>
        <TotalAmount resource="transaction/provider/expense" setTotal={setOtrosEgresosTotal} showInline />
      </List>
      <TransactionDialog open={open} handleClose={handleClose} record={selectedRecord} onDeleteSuccess={handleDeleteSuccess} isEdit={isEdit} onSuccess={refresh} />
      <div>
        <Paper>
          <Box sx={{ margin: 0, padding: 2, marginTop: 2, borderBottom: "1px solid #ddd" }}>
            <Typography variant="h5">Resumen de Ingresos y Egresos</Typography>
          </Box>
          <Box sx={{ margin: 0, padding: 2, paddingTop: 0, marginTop: 2 }}>
            <Typography variant="body1">
              Total Ingresos: Bs. {ingresosTotales.toFixed(2)}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              Total Egresos: Bs. {egresosTotales.toFixed(2)}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              Total: Bs. {total.toFixed(2)}
            </Typography>
          </Box>
        </Paper>
      </div>
    </Container>
  );
};

const SelectBranchInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const [searchParams] = useSearchParams(); 

  return <SelectInput {...props} label="Sucursal" optionText={(value) => value.name} optionValue="_id" parse={(value) => choicesContext?.allChoices.find((e) => e._id === value)} format={(value) => value?._id} validate={[required()]} defaultValue={choicesContext?.allChoices.find((e) => e._id === searchParams.get("branch"))} />;
};

const SelectEmployeeInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const branch = useFormContext().watch("branch");
  const { setValue } = useFormContext();
  const handleChange = (event) => {
    const selectedEmployee = choicesContext.allChoices.find((e) => e._id === event.target.value);
    setValue("employeeData", selectedEmployee);
  };

  return <SelectInput {...props} source={source} label="Empleado" optionText={(employee) => `${employee.first_name} ${employee.last_name}`} optionValue="_id" choices={branch ? choicesContext.allChoices.filter((employee) => employee.branch?._id === branch._id) : []} onChange={handleChange} />;
};

const TotalAmount = ({ resource, setTotal, showInline, totalType }) => {
  const dataProvider = useDataProvider();
  const [localTotal, setLocalTotal] = useState(0);

  useEffect(() => {
    const fetchTotalAmount = async () => {
      try {
        const response = await dataProvider.getList(resource, {
          pagination: { page: 1, perPage: 10 }, // No afecta los totales globales
        });

        let total = 0;

        if (totalType === "egresos") {
          total = response.totalFeeAmount || 0;
        } else {
          total = response.totalAmount || 0; 
        }

        setTotal(total);
        setLocalTotal(total);
      } catch (error) {
        setTotal(0);
        setLocalTotal(0);
      }
    };

    fetchTotalAmount();
  }, [dataProvider, resource, setTotal, totalType]);

  return showInline ? (
    <Typography variant="h6" sx={{ margin: 1, padding: 0, paddingLeft: 1 }}>
      Total: Bs. {localTotal.toFixed(2)}
    </Typography>
  ) : null;
};
