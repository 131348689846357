import React from "react";
import { TextInput } from "react-admin";
const RegexSearchInput = ({ source, label, alwaysOn = true }) => (
  <TextInput
    source={source}
    alwaysOn={alwaysOn}
    parse={(value) => `/${value}/i`}
    format={(value) => value?.slice(1, -2)}
    label={label}
    resettable
  />
);

export default RegexSearchInput;
