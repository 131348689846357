import React from "react";
import {
  Create,
  required,
  Filter,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ImageInput,
  NumberInput,
} from "react-admin";

const DeliverySettingFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="title" />
    <BooleanInput label="Estado" source="state" />
  </Filter>
);

export const DeliverySettingList = (props) => (
  <List filters={<DeliverySettingFilter />} {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="title" label="Nombre" />
      <TextField source="description" label="Descripción" />
      <ImageField source="image" label="Imagen" />
      <TextField source="fixedPrice" label="Precio Fijo (Bs.)" />
      <TextField source="variablePrice" label="Precio Variable (Bs/Km extra)" />
      <TextField source="distanceLimit" label="Límite de Distancia (Km.)" />
      <TextField source="weightLimit" label="Límite de peso (Kg.)" />
      <TextField source="volumeLimit" label="Límite de Volumen" />
      <TextField source="commissionRate" label="Comisión (%)" />
      <BooleanField source="state" label="Estado" />
    </Datagrid>
  </List>
);

export const DeliverySettingEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ImageInput
        validate={[required()]}
        source="image"
        label="Imagen"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput validate={[required()]} source="title" label="Nombre" />
      <TextInput
        validate={[required()]}
        source="description"
        label="Descripción"
        multiline
      />
      <NumberInput
        validate={[required()]}
        source="fixedPrice"
        label="Precio Fijo (Bs.)"
      />
      <NumberInput
        validate={[required()]}
        source="variablePrice"
        label="Precio Variable (Bs/Km extra)"
      />
      <NumberInput
        validate={[required()]}
        source="distanceLimit"
        label="Distancia Límite (Km)"
      />
      <NumberInput
        validate={[required()]}
        source="weightLimit"
        label="Límite de peso (Kg.)"
      />
      <TextInput source="volumeLimit" label="Límite de Volumen" />
      <NumberInput
        validate={[required()]}
        source="commissionRate"
        label="Comisión (%)"
      />
      <BooleanInput validate={[required()]} source="state" label="Estado" />
      <BooleanInput
        source="multipago"
        label="Pagos con tarjeta de crédito/débito (Multipago)"
      />
    </SimpleForm>
  </Edit>
);

export const DeliverySettingCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput
        validate={[required()]}
        source="image"
        label="Imagen"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput validate={[required()]} source="title" label="Nombre" />
      <TextInput
        validate={[required()]}
        source="description"
        label="Descripción"
        multiline
      />
      <NumberInput
        validate={[required()]}
        source="fixedPrice"
        label="Precio Fijo (Bs.)"
      />
      <NumberInput
        validate={[required()]}
        source="variablePrice"
        label="Precio Variable (Bs/Km extra)"
      />
      <NumberInput
        validate={[required()]}
        source="distanceLimit"
        label="Distancia Límite (Km)"
      />
      <NumberInput
        validate={[required()]}
        source="weightLimit"
        label="Límite de peso (Kg.)"
      />
      <TextInput source="volumeLimit" label="Límite de Volumen" />
      <NumberInput
        validate={[required()]}
        source="commissionRate"
        label="Comisión (%)"
      />
    </SimpleForm>
  </Create>
);
