import React from "react";
import {
  TextField,
  Edit as BaseEdit,
  SimpleForm,
  TextInput,
  DateField,
  SimpleShowLayout,
  NumberField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("/coupon");
    refresh();
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este registro? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};

export const Edit = ({ classes, ...props }) => {
  return (
    <BaseEdit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="name" label="Nombre" />
        <TextInput source="description" label="Descripcion" multiline />
        <SimpleShowLayout>
          <TextField source="code" label="Código" />
          <DateField source="expirationDate" label="Fecha de Expiracion" />
          <NumberField source="maxUses" label="Usos Máximos" />
          <NumberField source="usesLeft" label="Usos Restantes" />
          <TextField source="discountType" label="Tipo de Descuento" />
          <NumberField source="discount" label="Descuento" />
          <ReferenceArrayField
            source="validServices"
            label="Servicios Válidos"
            reference="service"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </SimpleShowLayout>
      </SimpleForm>
    </BaseEdit>
  );
};
