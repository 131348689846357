import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { useNotify, Notification } from "react-admin";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Background from "./assets/bg-desktop.png";
import teAyudoLogo from "./assets/te-ayudo-prov.png";
import { useCreate, useRedirect } from "react-admin";
import Switch from "@mui/material/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TyC from "./TyC";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box } from "@mui/material";

const PREFIX = 'Register';

const classes = {
  switchBase: `${PREFIX}-switchBase`,
  checked: `${PREFIX}-checked`,
  track: `${PREFIX}-track`
};

const StyledStyledEngineProvider = styled(StyledEngineProvider)({
  [`& .${classes.switchBase}`]: {
    color: "#FF770D",
    "&$checked": {
      color: "#FF770D",
    },
    "&$checked + $track": {
      backgroundColor: "#FF770D",
    },
  },
  [`& .${classes.checked}`]: {},
  [`& .${classes.track}`]: {},
});

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    minWidth: 300,
    backgroundColor: "transparent",
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
    marginBottom: 15,
    boxSizing: "content-box",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
};

const OrangeSwitch = Switch;

function TyCModal({ handleClose, open }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Términos y Condiciones de Te Ayudo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TyC />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Register = () => {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: "#FF770D",
      },
      secondary: {
        main: "#000",
      },
    },
  });
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [business_name, setBusiness_name] = useState("");
  const [tyc, setTyc] = useState(false);
  const [nit, setNit] = useState("");
  const redirect = useRedirect();
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [register, { loading }] = useCreate();

  useEffect(() => {
    setOpen(true);
  }, []);

  const submit = (e) => {
    e.preventDefault();
    if (!tyc) {
      return notify(
        "Debes aceptar los términos y condiciones de uso de la plataforma.",
        "warning"
      );
    } else {
      let user = {
        email,
        password,
      };
      if (password && email && first_name && phone) {
        if (confirmPassword === password) {
          register(
            "register",
            {
              data: {
                email,
                password,
                phone,
                first_name,
                role: "proveedor-empresa",
                provider_verified: false,
                CI_NIT: nit,
                business_name,
              },
            },
            {
              onSuccess: ({ data }) => {
                redirect("/login");
                notify("Cuenta registrada con éxito");
              },
              onFailure: (error) =>
                notify(`Error al crear cuenta: ${error}`, "warning"),
            }
          );
        } else {
          notify("Las contraseñas deben ser iguales", "warning");
        }
      } else {
        notify("Debes llenar todos los campos.", "warning");
      }
    }
  };

  return (
    <StyledStyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <div
          style={{
            ...styles.main,
            backgroundColor: "#252526",
            backgroundImage: `url(${Background})`,
            backgroundSize: "auto",
          }}
        >
          <Card style={styles.card}>
            <div style={styles.avatar}>
              <Avatar
                backgroundColor="transparent"
                src={teAyudoLogo}
                variant="square"
                style={{ height: 101, width: 254, borderRadius: 0 }}
              />
            </div>
            <form onSubmit={submit}>
              <div style={styles.form}>
                <div style={{ ...styles.input, marginBottom: 15 }}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Contraseña"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Confirmar Contraseña"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Nombre de Empresa"
                    name="first_name"
                    type="text"
                    value={first_name}
                    onChange={(e) => setFirst_name(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Razón Social"
                    name="business_name"
                    type="text"
                    value={business_name}
                    onChange={(e) => setBusiness_name(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="NIT"
                    name="nit"
                    type="text"
                    value={nit}
                    onChange={(e) => setNit(e.target.value)}
                  />
                </div>
                <div style={styles.input}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Número de Teléfono"
                    name="phone"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  color: "white",
                  textDecoration: "underline",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <a onClick={handleClickOpen}>
                  {" "}
                  Acepto los Términos y Condiciones de Te Ayudo:
                </a>
                <OrangeSwitch
                  checked={tyc}
                  onChange={() => setTyc(!tyc)}
                  name="checkedB"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.checked,
                    track: classes.track
                  }} />
              </div>
              <TyCModal handleClose={handleClose} open={open}></TyCModal>
              <Box sx={{ "& button": { m: 1 }, "& a": { m: 1 } }}>
                <Button variant="contained" type="submit" fullWidth>
                  Registrarse
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  href="login"
                  fullWidth
                >
                  Iniciar Sesión
                </Button>
              </Box>
            </form>
          </Card>
          <Notification />
        </div>
      </ThemeProvider>
    </StyledStyledEngineProvider>
  );
};

export default Register;
