import React from "react";
import { Card, CardContent } from "@mui/material";

const AirtablePage = () => {
  return (
    <Card sx={{ width: "100%", height: "100%", margin: 0, padding: 0, overflow: "hidden", boxShadow: 3, marginTop: "1rem", marginBottom: "1rem" }}>
      <CardContent sx={{ height: "100%", padding: 0 }}>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/app1FGSxcH2YbrRNt/shrCs6dubN0W0Tlwn/tblDRXEbUdJbA77iP?viewControls=on"
          frameBorder="0"
          width="100%"
          height="100%"
          style={{
            background: "transparent",
            border: "none",
          }}
          title="Airtable"
        />
      </CardContent>
    </Card>
  );
};

export default AirtablePage;
