import React from "react";
import { styled } from '@mui/material/styles';
import {
  Create,
  Filter,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ImageInput,
  NumberInput,
  required,
  NumberField,
} from "react-admin";
const PREFIX = 'ProductList';

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`
};

const StyledCreate = styled(Create)({
  [`& .${classes.inlineBlock}`]: { display: "inline-flex", marginRight: "1rem" },
});

const TitleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="name" alwaysOn />
  </Filter>
);

function formatImage(value) {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

export const ProductList = (props) => (
  <List filters={<TitleFilter />} {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Name" />
      <ImageField source="image" title="image" />
      <TextField source="price" label="Precio (Bs.)" />
      <TextField source="description" label="Descripción" />
      <BooleanField source="state" label="Habilitado" />
      <NumberField source="order" label="Orden" />
    </Datagrid>
  </List>
);

export const ProductEdit = (({  ...props }) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="title" label="Título" />
      <ImageInput
        format={formatImage}
        source="image"
        label="Imagen de Producto"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="Imagen" />
      </ImageInput>
      <TextInput
        validate={[required()]}
        multiline
        source="description"
        label="Descripción"
      />
      <NumberInput
        validate={[required()]}
        source="price"
        placeholder="Bs."
        label="Precio"
      />
      <NumberInput source="order" label="Orden" />
      <BooleanInput validate={[required()]} source="state" label="Habilitado" />
    </SimpleForm>
  </Edit>
));
export const ProductCreate = (({  ...props }) => (
  <StyledCreate {...props}>
    <SimpleForm>
      <TextInput validate={[required()]} source="name" label="Nombre" />
      <ImageInput
        source="image"
        label="Imagen de Producto"
        accept=".jpeg,.jpg,.png,.jfif"
        placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput
        validate={[required()]}
        multiline
        source="description"
        label="Descripción"
      />
      <NumberInput
        validate={[required()]}
        source="price"
        placeholder="Bs."
        label="Precio"
      />
      <NumberInput source="order" label="Orden" />
    </SimpleForm>
  </StyledCreate>
));
