import React, { useState } from "react";
import { ArrayInput, AutocompleteInput, Create, DateInput, email, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, SimpleFormIterator, TextInput, useChoicesContext, useCreate, useNotify } from "react-admin";
import { useFormContext } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress } from "@mui/material";
import Map from "../components/Map";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

export const SelectCustomerInput = (props) => {
  const { watch } = useFormContext();
  const customer = watch("customer");
  const address = watch("address");
  const isInBranch = useFormContext().watch("isInBranch");
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [create] = useCreate();

  const postCustomer = async (data) => {
    setLoading(true);
    try {
      await create(
        "enterprise/customers",
        { data },
        {
          onSuccess: () => {
            notify("Cliente creado correctamente", { type: "info" });
            setLoading(false);
            handleClose();
          },
          onFailure: (error) => {
            notify(`Error: ${error.message}`, { type: "warning" });
            setLoading(false);
          },
        }
      );
    } catch (error) {
      notify(`Error: ${error.message}`, { type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <ReferenceInput label="Cliente" source="customer" reference="enterprise/customers">
        <AutocompleteCustomer />
      </ReferenceInput>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleOpen}
        sx={{
          color: "secondary.menuIcon",
          border: "none",
          backgroundColor: "white",
          "&:hover": {
            border: "none",
          },
        }}
      >
        Crear Nuevo Cliente
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ padding: "1rem 1.5rem" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Crear Nuevo Cliente</Typography>
            {/* <Button onClick={handleClose} variant="text">
              ✕
            </Button> */}
          </Box>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            overflowY: "auto",
            maxHeight: "60vh",
            padding: "1rem 1.5rem",
          }}
        >
          <SimpleForm
            onSubmit={postCustomer}
            id="customer-create-form" 
            toolbar={false}
          >
            <TextInput validate={[required()]} source="fullName" label="Nombre Completo" />
            <NumberInput source="phone" label="Teléfono/Celular" />
            <TextInput validate={[email()]} source="email" label="Email" />
            <DateInput label="Fecha de Nacimiento" source="birthdate" />
            <SelectInput
              label="Género"
              source="gender"
              choices={[
                { id: "hombre", name: "Hombre" },
                { id: "mujer", name: "Mujer" },
              ]}
            />
            <ArrayInput source="addresses" label="Direcciones">
              <SimpleFormIterator>
                <Map validate={[required()]} source="coordinates" initialLatitude={0} initialLongitude={0} disabled />
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <TextInput validate={[required()]} source="name" label="Nombre" />
                  <TextInput validate={[required()]} source="street" label="Calle" />
                  <TextInput source="house" label="Número de casa" />
                  <TextInput source="reference" label="Referencia" />
                </Box>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between", padding: "1rem" }}>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            type="submit"
            form="customer-create-form" // Asegúrate de que coincida con el id del formulario
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {!isInBranch && <SelectInput source="address" label="Dirección" choices={customer?.addresses || []} disabled={!customer} optionText={(value) => value?.name ?? value?.street} optionValue="_id" validate={[required("La dirección es requerida")]} />}
      {address && <Map source={"address.coordinates"} initialLatitude={address?.coordinates?.latitude} initialLongitude={address?.coordinates?.longitude} disabled />}
    </>
  );
};

const AutocompleteCustomer = () => {
  const choicesContext = useChoicesContext();
  return (
    <AutocompleteInput
      validate={[required()]}
      source="customer"
      label="Cliente"
      optionText="fullName"
      parse={(value) => choicesContext.allChoices.find((e) => e._id === value)}
      clearOnBlur={false}
      filterToQuery={(text) => ({
        filter: JSON.stringify({
          $or: [{ fullName: { $regex: text, $options: "i" } }, { phone: { $regex: text, $options: "i" } }],
        }),
      })}
    />
  );
};
