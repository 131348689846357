// import { Button } from '@mui/material';
import React from "react";
import { saveAs } from "file-saver";
import { Button } from "react-admin";
import DescriptionIcon from "@mui/icons-material/Description";
const DownloadCsvButton = () => {
  const handleDownload = () => {
    const csvData =
      "Nombre,Telefono,Email\nJuan Perez,72145789,juanperez@gmail.com";
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "importacion-clientes.csv");
  };

  return (
    <Button
      color="primary"
      onClick={handleDownload}
      component="span"
      label="Plantilla"
    >
      <DescriptionIcon />
    </Button>
  );
};

export default DownloadCsvButton;
