import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { useEffect } from "react";
import { useUpdate } from "react-admin";

const firebaseConfig = {
  apiKey: "AIzaSyCXMEs7-Toa0pjyUnxoHWqa-vdTPy9NcyA",
  authDomain: "teayudo.firebaseapp.com",
  databaseURL: "https://teayudo.firebaseio.com",
  projectId: "teayudo",
  storageBucket: "teayudo.appspot.com",
  messagingSenderId: "837792396616",
  appId: "1:837792396616:web:dcf3fcff8cb738739caf7d",
  measurementId: "G-TBQ36Z1GFX",
};

const firebaseApp = initializeApp(firebaseConfig);

export let messaging = null;

const supported = isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(firebaseApp);
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      }
    });
  }
});
// console.log(supported)

const vapidKey =
  "BD1vFPfVyUOp3JjEXvTbzYvo--bd6MYHZpru8wGtcHBmFIwHDSmfkj_VFwUbyeKysECDZbjIHNdZ6EHQMJ3sG_U";

export default function Firebase({ children }) {
  const [update, { loading }] = useUpdate();

  useEffect(() => {
    async function swRegistration() {
      return navigator.serviceWorker.register(
        process.env.PUBLIC_URL + "/serviceWorker.js"
      );
    }
    async function _getToken() {
      getToken(messaging, {
        vapidKey: vapidKey,
        serviceWorkerRegistration: await swRegistration(),
      })
        .then((currentToken) => {
          if (
            currentToken &&
            JSON.parse(localStorage.getItem("profile"))?.role != "marketing"
          ) {
            console.log(currentToken);
            update(
              "web-tokens",
              {
                id: null,
                data: {
                  token: currentToken,
                },
              },
              {
                onFailure: (error) => console.log(error),
              }
            );
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
    _getToken();
  }, []);

  return children;
}
