import { useEffect, useRef } from "react";
import { useState } from "react";
import { GiftedChat } from "react-gifted-chat";
import Fab from "@mui/material/Fab";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { createDataForm, getResizedImage } from "./components/dataProvider";
import { useCreate } from "react-admin";
const { io } = require("socket.io-client");

export const Chat = ({ orderId, orderType, userId }) => {
  const [socket, setSocket] = useState(null);
  const [chat, setChat] = useState([]);
  const [create, { loading }] = useCreate();
  const [text, setText] = useState("");
  const connectSocket = async () => {
    let token = localStorage.getItem("token");
    const socketOptions = {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: "Bearer " + token,
          },
        },
      },
    };
    const socket = await io(
      `${process.env.REACT_APP_SOCKET_URL}`,
      socketOptions
    );
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connected");
      socket.emit(
        "findChat",
        {
          orderId: Number(orderId),
          orderType: orderType,
        },
        (response) => {
          setChat(response.messages);
        }
      );
    });

    socket.on("message", (response) => {
      setChat(response.messages);
    });
    return socket;
  };

  useEffect(() => {
    const socket = connectSocket();
    return () => {
      // socket.disconnect();
    };
  }, []);

  const sendMessage = (message) => {
    socket.emit("addMessage", {
      orderId: orderId,
      orderType: orderType,
      message: message,
    });
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      sendMessage(giftedChat.current.state.text.trim());
      giftedChat.current.state.text = "";
      giftedChat.current.textInput.clear();
    }
  };
  const giftedChat = useRef(null);

  const uploadImage = (image) =>
    create(`chat/${orderType}/${orderId}/uploadImage`, {
      data: { image },
    });

  const handleFileUpload = async (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", await getResizedImage(file, 800, "JPEG"));
    // console.log(file);
    createDataForm(`chat/${orderType}/${orderId}/uploadImage`, formData);
    // uploadImage(formData)
  };

  return (
    <GiftedChat
      ref={giftedChat}
      messages={chat}
      onSend={(messages) => {
        sendMessage(messages[0].text);
      }}
      user={{
        _id: userId,
      }}
      locale="es"
      placeholder="Escribe un mensaje..."
      renderUsernameOnMessage={true}
      inverted={false}
      textInputProps={{ onKeyPress: handleKeypress }}
      text={text}
      onInputTextChanged={(text) => setText(text.replace(/[0-9]/g, ""))}
      renderActions={() => (
        <label htmlFor="upload-photo">
          <input
            style={{ display: "none" }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleFileUpload}
          />

          <Fab
            color="secondary"
            size="small"
            component="span"
            aria-label="add"
            variant="extended"
          >
            <AddPhotoAlternateIcon />
          </Fab>
        </label>
      )}
    />
  );
};
