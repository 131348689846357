import React, { useState, useEffect } from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SelectInput,
  useUpdate,
  useGetList,
  usePermissions,
} from "react-admin";
import Button from "@mui/material/Button";
import {
  Loading,
  Error,
  useShowController,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Select, MenuItem, InputLabel, FormControl, Box } from "@mui/material";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Grid from "@mui/material/Grid";
import MuiTextField from "@mui/material/TextField";
import { Chat } from "../Chat";

const UseEmployee = (props) => {
  const { data, isLoading, error } = useGetList("employeeSale");
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  return (
    <FormControl style={{ minWidth: 150 }}>
      <MuiTextField
        className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense"
        autoWidth
        select
        onChange={(e) => {
          props.setEmployee(e.target.value);
        }}
        labelId="employee"
        children={data
          ?.filter((e) => e.state)
          .map((e) => {
            return (
              <MenuItem value={e}>{e.first_name + " " + e.last_name}</MenuItem>
            );
          })}
        label="Empleado Asignado"
      />
    </FormControl>
  );
};

const SaleFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Estado"
        source="state"
        choices={[
          {
            id: "Pendiente de confirmación",
            name: "Pendiente de confirmación",
          },
          { id: "Confirmado", name: "Confirmado" },
          { id: "En proceso", name: "En proceso" },
          { id: "Pendiente de Pago", name: "Pendiente de Pago" },
          { id: "Concluido", name: "Concluido" },
          { id: "Reviewed", name: "Reviewed" },
          {
            id: "Cancelada por el proveedor",
            name: "Cancelada por el proveedor",
          },
          { id: "Cancelada por el cliente", name: "Cancelada por el cliente" },
        ]}
      />
    </Filter>
  );
};

export const SaleList = (props) => {
  const refresh = useRefresh();

  useEffect(() => {
    const interval = setInterval(() => {
      refresh();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let [refreshDate, setRefreshDate] = useState(new Date());
  return (
    <List
      sort={{ field: "startDate", order: "ASC" }}
      bulkActionButtons={false}
      filters={<SaleFilter />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <IconField refreshDate={refreshDate} />
        <TextField source="_id" label="ID" />
        <DateField source="createdAt" label="Fecha" showTime locales="es-ES" />
        <TextField source="totalPrice" label="Precio (Bs.)" />
        <TextField source="state" label="Estado de Venta" />
        <TextField source="paymentMethod" label="Método de Pago" />
        <TextField source="paymentStatus" label="Estado de Pago" />
      </Datagrid>
    </List>
  );
};

export const SaleShow = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  let [employee, setEmployee] = useState("");
  const { record } = useShowController(props);
  const [_update, { loading }] = useUpdate();
  const { permissions } = usePermissions();
  const update = (withEmployee = true) =>
    _update(
      `sale/${record?._id}/assignEmployee`,
      {
        data: {
          employee: withEmployee
            ? {
                _id: employee?._id,
                fullName: employee?.first_name + " " + employee?.last_name,
                phone: employee?.phone,
                photoURL: employee?.picture,
                pushToken: employee?.pushToken,
                CI: employee?.CI_NIT,
                location: [],
              }
            : null,
        },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/sale");
          notify("Empleado asignado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al asignar empleado: ${error}`, "warning"),
      }
    );

  const submit = (e, witEmployee = true) => {
    e.preventDefault();
    if (witEmployee) {
      if (employee) {
        return update();
      } else {
        return notify("Asignar al empleado.", "warning");
      }
    }
    update(witEmployee);
  };

  const cancel = () =>
    _update(
      "sale",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toCancelledByProvider" },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/sale");
          notify("venta cancelada con éxito");
        },
        onFailure: (error) =>
          notify(`Error al cancelar venta: ${error}`, "warning"),
      }
    );

  const submitCancel = (e) => {
    e.preventDefault();
    cancel();
  };

  const finish = () =>
    _update(
      "sale",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toFinished" },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/sale");
          notify("venta finalizada con éxito");
        },
        onFailure: (error) =>
          notify(`Error al finalizar venta: ${error}`, "warning"),
      }
    );

  const submitFinish = (e) => {
    e.preventDefault();
    finish();
  };

  const pay = () =>
    _update(
      "sale",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toPaid" },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/sale");
          notify("venta pagada con éxito");
        },
        onFailure: (error) =>
          notify(`Error al pagar venta: ${error}`, "warning"),
      }
    );

  const submitPay = (e) => {
    e.preventDefault();
    pay();
  };

  const start = () =>
    _update(
      "sale",
      {
        id: record._id,
        data: {},
        meta: { customAction: "toProcessing" },
      },
      {
        onSuccess: ({ data }) => {
          redirect("/sale");
          notify("Iniciado con éxito");
        },
        onFailure: (error) =>
          notify(`Error al iniciar venta: ${error}`, "warning"),
      }
    );

  const submitStart = (e) => {
    e.preventDefault();
    start();
  };
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Información de la Venta">
          <TextField source="state" label="Estado" />
          <DateField
            showTime
            source="createdAt"
            label="Fecha"
            locales="es-ES"
          />

          <TextField source="totalPrice" label="Precio Total (Bs)" />
          <TextField source="paymentMethod" label="Método de Pago" />
          <TextField source="paymentStatus" label="Estado de Pago" />
          <TextField source="totalPrice" label="Precio Total (Bs)" />
          {record && record.saleDetails && (
            <ArrayField source="saleDetails" label="Productos">
              <Datagrid bulkActionButtons={false}>
                <TextField source="product.name" label="Nombre" />
                <TextField source="quantity" label="Cantidad" />
                <TextField
                  source="product.price"
                  label="Precio Unitario (Bs)"
                />
              </Datagrid>
            </ArrayField>
          )}
          {record && record.comment && (
            <TextField source="comment" label="Comentario" />
          )}
          {permissions === "proveedor-producto" && (
            <Box>
              {record?.employee?._id && (
                <h4>
                  Empleado asignado:{" "}
                  {record.employee?.fullName
                    ? record.employee.fullName
                    : (record.employee?.first_name || "") +
                      " " +
                      (record.employee?.last_name || "")}
                </h4>
              )}
              {record &&
              (record.state === "Pendiente de confirmación" ||
                record.state === "Confirmado") ? (
                <UseEmployee setEmployee={setEmployee} idApp={record._id} />
              ) : null}
              <br />
              <br />
              {record &&
              (record.state === "Pendiente de confirmación" ||
                record.state === "Confirmado") ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => submit(e, true)}
                >
                  Asignar Empleado
                </Button>
              ) : null}
              <br />
              <br />
              {record && record.state === "Pendiente de confirmación" ? (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(e) => submitCancel(e)}
                >
                  Cancelar Reserva
                </Button>
              ) : null}

              {record?.state === "En proceso" ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submitFinish}
                >
                  Finalizar
                </Button>
              ) : null}

              {record?.state === "Confirmado" && record.employee?._id ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submitStart}
                >
                  Iniciar
                </Button>
              ) : null}

              {record?.state === "Concluido" &&
              record?.paymentStatus === "Pendiente" &&
              record?.paymentMethod === "Efectivo" &&
              record.employee?._id ? (
                <Button color="primary" variant="contained" onClick={submitPay}>
                  Pagado
                </Button>
              ) : null}
            </Box>
          )}
        </Tab>
        <Tab label="Información del Cliente">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 style={{ textAlign: "center" }}>
                {record?.client.fullName}{" "}
              </h2>
              <span className="MuiTypography-root MuiTypography-body2">
                Teléfono: {record?.client?.phone}
              </span>
            </Grid>
            <Grid item xs={12} md={record?.directionDestination ? 6 : 12}>
              <h3>Dirección del cliente</h3>
              <div style={{ height: "50vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                  }}
                  defaultCenter={{
                    lat: record?.direction?.coordinates?.latitude,
                    lng: record?.direction?.coordinates?.longitude,
                  }}
                  defaultZoom={15}
                >
                  <RoomIcon
                    lat={record?.direction.coordinates.latitude}
                    lng={record?.direction.coordinates.longitude}
                    style={{
                      fontSize: 40,
                      position: "relative",
                      top: -40,
                      left: -20,
                    }}
                  />
                </GoogleMapReact>
              </div>

              <span className="MuiTypography-root MuiTypography-body2">
                {record?.direction.street} #{record?.direction.house},{" "}
                {record?.direction.reference}
              </span>
            </Grid>
          </Grid>
          <br></br>
        </Tab>
        {(record?.state === "Confirmado" || record?.state === "En proceso") && (
          <Tab label="Chat">
            <div style={{ display: "flex", height: 500 }}>
              <Chat
                orderId={record._id}
                orderType="sale"
                userId={record.provider._id}
              />
            </div>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

const IconField = ({ source, record = {}, refreshDate }) => {
  var d1 = new Date(refreshDate);
  var d2 = new Date(record.saleDate);
  if (d2 > d1)
    return <NewReleasesIcon color="primary" fontSize="medium" label="Nuevo" />;
  else {
    return null;
  }
};
