import React from "react";
import { UserMenu, MenuItemLink, Logout } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";

const MyUserMenuView = (props) => {
  return (
    <UserMenu {...props}>
      {JSON.parse(localStorage.getItem("profile"))?.role != "admin" && (
        <MenuItemLink
          to="/my-profile"
          primaryText="Mi Perfil"
          leftIcon={<SettingsIcon />}
        />
      )}
      {JSON.parse(localStorage.getItem("profile"))?.role != "admin" && (
        <MenuItemLink
          to="/update-password"
          primaryText="Cambiar Contraseña"
          leftIcon={<SettingsIcon />}
        />
      )}
      <Logout />
    </UserMenu>
  );
};

export default MyUserMenuView;
