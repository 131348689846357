// in src/users.js
import React from "react";
import {
  Filter,
  TextInput,
  required,
  FormDataConsumer,
  ImageField,
  Edit,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  BooleanField,
  BooleanInput,
  EmailField,
  SimpleShowLayout,
} from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre de Empresa" source="first_name" />
    <TextInput label="Razón Social" source="business_name" />
    <BooleanInput label="Usuario Habilitado" source="state"></BooleanInput>
    <BooleanInput
      label="Proveedor Verificado"
      source="provider_verified"
    ></BooleanInput>
  </Filter>
);

export const UserList = (props) => (
  <List filters={<UserFilter />} {...props}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField source="first_name" label="Nombre de Empresa" />
      <TextField source="business_name" label="Razón Social" />
      <ImageField
        source="picture"
        title="Imagen  no disponible"
        label="Imagen"
      />
      <EmailField source="email" />
      <TextField source="phone" label="Teléfono" />
      <TextField source="CI_NIT" label="NIT" />
      <TextField source="role" label="Rol" />
      <BooleanField source="email_verified" label="Email verificado" />
      <BooleanField source="provider_verified" label="Proveedor verificado" />
      <BooleanField source="state" label="Usuario Habilitado" />
      <BooleanField source="providerEnabledForApp" label="Habilitado para app movil" />
    </Datagrid>
  </List>
);

const WithProps = ({ children, ...props }) => children(props);

export const UserEdit = (props, record) => {
  return (
    <Edit {...props}>
      <WithProps>
        {({ record, ...props }) => {
          return (
            <SimpleForm>
              <SimpleShowLayout>
                <TextField source="first_name" label="Nombre de Empresa" />
                <TextField source="business_name" label="Razón Social" />
                <ImageField
                  source="pictureURL"
                  title="Imagen no disponible"
                  label="Imagen"
                />
                <EmailField source="email" />
                <TextField source="phone" label="Teléfono" />
                <TextField source="CI_NIT" label="NIT" />
                <TextField source="role" label="Rol" />
                <BooleanField
                  source="email_verified"
                  label="Email verificado"
                />
              </SimpleShowLayout>
              <BooleanInput
                source="provider_verified"
                label="Proveedor verificado"
              />
              <BooleanInput source="state" label="Usuario Habilitado" />
              <BooleanInput source="providerEnabledForApp" label="Habilitado para app movil" />
              {/* <FormDataConsumer>
              </FormDataConsumer> */}
            </SimpleForm>
          );
        }}
      </WithProps>
    </Edit>
  );
};
