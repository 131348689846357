import React, { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Background from "./assets/bg-desktop.png";
import avatarLogo from "./assets/avatar.png";
import teAyudoLogo from "./assets/te-ayudo-prov.png";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    minWidth: 300,
    width: 300,
    backgroundColor: "transparent",
  },
  avatar: {
    margin: "1em",
    textAlign: "center ",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
};
const MyLoginPage = () => {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: "#FF770D",
      },
      secondary: {
        main: "#000",
      },
    },
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    login({ email, password }).catch((e) => notify(e, "warning"));
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <div
          style={{
            ...styles.main,
            backgroundColor: "#252526",
            backgroundImage: `url(${Background})`,
            backgroundSize: "auto",
          }}
        >
          <Card style={styles.card}>
            <div style={styles.avatar}>
              <Avatar
                backgroundColor="transparent"
                src={avatarLogo}
                variant="square"
                style={{ height: 350, width: 225, borderRadius: 0 }}
              />
            </div>
            <div style={styles.avatar}>
              <Avatar
                backgroundColor="transparent"
                src={teAyudoLogo}
                variant="square"
                style={{ height: 105, width: 254, borderRadius: 0 }}
              />
            </div>
            {process.env.REACT_APP_API_URL ==
              "https://teayudo.com.bo/test/api" && (
              <p style={{ color: "white" }}>TEST</p>
            )}
            <form onSubmit={submit}>
              <div style={styles.form}>
                <div style={{ ...styles.input, marginBottom: 15 }}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: 5 }}
                    fullWidth
                    variant="filled"
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      sx: { height: 50 },
                    }}
                  />
                </div>
                {/* <div style={styles.input}> */}
                <TextField
                  style={{ backgroundColor: "white", borderRadius: 5 }}
                  fullWidth
                  variant="filled"
                  label="Contraseña"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    sx: { height: 50 },
                  }}
                />
                {/* </div> */}
              </div>
              <Box sx={{ "& button": { m: 1 }, "& a": { m: 1 } }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Iniciar Sesión
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  href={process.env.PUBLIC_URL + "/#/register"}
                >
                  Registrarse
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  href={
                    process.env.PUBLIC_URL +
                    "/#/forgotPassword?role=proveedor-empresa"
                  }
                >
                  ¿Olvidaste tu contraseña?
                </Button>
              </Box>
            </form>
          </Card>
          <Notification />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MyLoginPage;
