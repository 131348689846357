// in src/users.js
import React from "react";
import {
  Create,
  required,
  List,
  Datagrid,
  TextField,
  DateField,
  SimpleForm,
  SelectInput,
  TextInput,
} from "react-admin";

export const NotificationList = (props) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="title" label="Título" />
      <TextField source="message" title="Mensaje" />
      <TextField source="type" label="Tipo" />
      <DateField
        source="createdAt"
        label="Enviado el:"
        showTime
        locales="es-ES"
      />
    </Datagrid>
  </List>
);

export const NotificationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" label="Título" validate={[required()]} />
      <TextInput
        source="message"
        label="Mensaje"
        validate={[required()]}
        multiline
      />
      <SelectInput
        validate={[required()]}
        source="type"
        label="Tipo"
        choices={[
          { id: "global", name: "Global" },
          { id: "cliente", name: "Clientes" },
          { id: "proveedor-empleado", name: "Empleados" },
        ]}
      />
    </SimpleForm>
  </Create>
);
