import React, { useState } from "react";
import {
  Create,
  required,
  Filter,
  ImageField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  ImageInput,
  PasswordInput,
  NumberInput,
  useGetList,
  usePermissions,
  SimpleShowLayout,
  useChoicesContext,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  TopToolbar,
  FilterButton,
  CreateButton,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { Loading, Error } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { getFinalFor, getInitialFor } from "./availability";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import useExport from "./UseExcelExport";
import ExportButton from "./ExcelExportButton";

const PREFIX = "AvailabilityEdit";
const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const UseDeliverySetting = () => {
  const { data, isLoading, error } = useGetList("deliverysetting");
  let [vehicle, setVehicle] = useState("");
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div>
      <SelectInput
        onChange={(e) => {
          setVehicle(e.target.value);
        }}
        source="deliveryVehicle"
        label="Vehículo"
        choices={data.map((e) => {
          let obj = JSON.stringify({ _id: e._id, title: e.title });
          return { id: obj, name: e.title };
        })}
      />
    </div>
  );
};

const EmployeeFilter = [
    <TextInput label="Nombre" source="first_name" />, 
    <TextInput label="Apellido" source="last_name" />
];

function formatLogo(value) {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
}

const ListActions = (props) => {
  const { getExportData, exportData, loading } = useExport("employee");
  const [openModal, setOpenModal] = useState(false);

  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData();
      setOpenModal(false);
    }
  };

  const { className, basePath } = props;

  const columns = [
    { label: "Nombre", source: "first_name" },
    { label: "Apellido", source: "last_name" },
    { label: "Email", source: "email" },
    { label: "Teléfono", source: "phone" },
    { label: "Estado", source: "state", transform: (value) => (value ? "habilitado" : "no habilitado") },
    { label: "Método", source: "serviceMethod" },
    { label: "Sucursal", source: "branch.name" },
  ];

  return (
    <TopToolbar
      className={className}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        <FilterButton filters={EmployeeFilter} />
        <CreateButton basePath={basePath} />
      </div>
      <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="empleados.xlsx" loading={loading} />
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const EmployeeList = (props) => {
  const { permissions } = usePermissions();
  return (
    <List actions={<ListActions/>} {...props}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="first_name" label="Nombre" />
        <TextField source="last_name" label="Apellido" />
        <ImageField source="picture" label="Foto" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Teléfono" />
        <BooleanField source="state" label="Estado" />
        {permissions === "admin" && <TextField source="deliveryVehicle.title" label="Vehículo" />}
        <TextField source="serviceMethod" label="Método" />
        <TextField source="branch.name" label="Sucursal" />
      </Datagrid>
    </List>
  );
};

// CustomToolbar con los botones de Guardar y Eliminar
const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("/employees"); // Redirecciona a la lista de empleados
    refresh();
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este empleado? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};




export const EmployeeEdit = (props) => {
  const { permissions } = usePermissions();
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <ImageInput
          format={formatLogo}
          validate={[required()]}
          source="picture"
          label="Foto de Empleado"
          accept=".jpeg,.jpg,.png,.jfif"
          placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput validate={[required()]} source="first_name" label="Nombre" />
        <TextInput
          validate={[required()]}
          source="last_name"
          label="Apellido"
        />
        <BooleanInput validate={[required()]} source="state" label="Estado" />
        <NumberInput validate={[required()]} source="phone" label="Teléfono" />
        <SimpleShowLayout>
          <TextField validate={[required()]} source="email" />
          {permissions === "admin" && (
            <TextField source="deliveryVehicle.title" label="Vehículo" />
          )}
        </SimpleShowLayout>
        <ServiceSelection />
        {/* <SelectInput
          validate={[required()]}
          source="serviceMethod"
          label="Método de Servicio"
          choices={[
            { id: "En sucursal", name: "En sucursal" },
            { id: "A domicilio", name: "A domicilio" },
            { id: "Ambos", name: "Ambos" },
          ]}
        />

        <ReferenceArrayInput
          source="availableServices"
          reference="service"
          queryOptions={{ filter: { method: "serviceMethod" } }}
        >
          <SelectArrayInput label="Servicios Disponibles"  />
        </ReferenceArrayInput> */}

        <ArrayInput source="availability" label="Disponibilidad">
          <SimpleFormIterator>
            <SelectInput
              validate={[required()]}
              source="day"
              label="Día"
              choices={[
                { id: "L", name: "Lunes" },
                { id: "M", name: "Martes" },
                { id: "MM", name: "Miércoles" },
                { id: "J", name: "Jueves" },
                { id: "V", name: "Viernes" },
                { id: "S", name: "Sábado" },
                { id: "D", name: "Domingo" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("startHour")}
                  label="Hora de Inicio"
                  //  onChange={(e) => { console.log(e) }}
                  choices={getInitialFor(scopedFormData?.endHour)}
                />
              )}
            </FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="startMinute"
              label="Minuto de inicio"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("endHour")}
                  label="Hora Final" //formClassName={classes.inlineBlock}
                  choices={getFinalFor(scopedFormData?.startHour)}
                  formClassName={classes.inlineBlock}
                />
              )}
            </FormDataConsumer>
            {/* <SelectInput validate={[required()]} source="endHour" label="Hora Final" formClassName={classes.inlineBlock}
                            choices={finalHour} /> */}
            <SelectInput
              validate={[required()]}
              source="endMinute"
              label="Minuto final"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const EmployeeCreate = (props) => {
  const { permissions } = usePermissions();
  return (
    <Create {...props}>
      <SimpleForm>
        <ImageInput
          validate={[required()]}
          source="picture"
          label="Foto de Empleado"
          accept=".jpeg,.jpg,.png,.jfif"
          placeholder={<p>Arrastra tu imagen o haz clic aquí.</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <TextInput validate={[required()]} source="first_name" label="Nombre" />
        <TextInput
          validate={[required()]}
          source="last_name"
          label="Apellido"
        />

        <TextInput validate={[required()]} source="email" label="Email" />
        <PasswordInput
          validate={[required()]}
          source="password"
          label="Contraseña"
        />
        <NumberInput validate={[required()]} source="phone" label="Teléfono" />
        <NumberInput validate={[required()]} source="CI_NIT" label="CI" />
        {permissions === "admin" && <UseDeliverySetting />}
        <ServiceSelection />
        <ArrayInput source="availability" label="Disponibilidad">
          <SimpleFormIterator>
            <SelectInput
              validate={[required()]}
              source="day"
              label="Día"
              choices={[
                { id: "L", name: "Lunes" },
                { id: "M", name: "Martes" },
                { id: "MM", name: "Miércoles" },
                { id: "J", name: "Jueves" },
                { id: "V", name: "Viernes" },
                { id: "S", name: "Sábado" },
                { id: "D", name: "Domingo" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("startHour")}
                  label="Hora de Inicio"
                  //  onChange={(e) => { console.log(e) }}
                  choices={getInitialFor(scopedFormData?.endHour)}
                />
              )}
            </FormDataConsumer>
            <SelectInput
              validate={[required()]}
              source="startMinute"
              label="Minuto de inicio"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />

            <FormDataConsumer formClassName={classes.inlineBlock}>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) => (
                <SelectInput
                  validate={[required()]}
                  source={getSource("endHour")}
                  label="Hora Final" //formClassName={classes.inlineBlock}
                  choices={getFinalFor(scopedFormData?.startHour)}
                  formClassName={classes.inlineBlock}
                />
              )}
            </FormDataConsumer>
            {/* <SelectInput validate={[required()]} source="endHour" label="Hora Final" formClassName={classes.inlineBlock}
                            choices={finalHour} /> */}
            <SelectInput
              validate={[required()]}
              source="endMinute"
              label="Minuto final"
              formClassName={classes.inlineBlock}
              choices={[
                { id: "0", name: "00" },
                { id: "30", name: "30" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const SelectBranchInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  const serviceMethod = useFormContext().watch("serviceMethod");
  return serviceMethod && serviceMethod !== "A domicilio" ? (
    <SelectInput
      {...props}
      label="Sucursal"
      optionText={(value) => {
        return value.name;
      }}
      optionValue="_id"
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
      validate={[required()]}
    />
  ) : (
    <React.Fragment />
  );
};

const ServiceSelection = () => {
  const form = useWatch("serviceMethod");

  React.useEffect(() => {
    console.log("method", form.serviceMethod);
  }, [form]);

  return (
    <>
      <SelectInput
        validate={[required()]}
        source="serviceMethod"
        label="Método de Servicio"
        choices={[
          { id: "En sucursal", name: "En sucursal" },
          { id: "A domicilio", name: "A domicilio" },
          { id: "Ambos", name: "Ambos" },
        ]}
      />
      <ReferenceInput
        label="Sucursal"
        source="branch"
        reference="enterprise/branches"
        filter={{
          status: true,
        }}
      >
        <SelectBranchInput />
      </ReferenceInput>

      <ReferenceArrayInput
        source="availableServices"
        reference="service"
        filter={{ method: form.serviceMethod, enabled: true }}
      >
        <SelectArrayInput label="Servicios Disponibles" />
      </ReferenceArrayInput>
    </>
  );
};
